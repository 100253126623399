import { FormattedMessage, useIntl } from 'react-intl';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { Button } from '../../atoms/button/button';
import { getElementError } from '@hooks/use-form-validation';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import InputVerification, {
  VerificationVariant,
} from '@components/atoms/input-verification/input-verification';
import { Text } from '@components/atoms/text/text';
import useLoginSecondFactorForm from '@hooks/use-login-second-factor-form';
import { SecondFactor } from '@models/user';
import { TextUppercase } from '@components/atoms/text-uppercase/text-uppercase';

interface Custom2faLabel {
  label?: string;
  description?: string;
}

type Custom2faLabels = {
  [key in SecondFactor]?: Custom2faLabel;
};

interface Params {
  isOauth: boolean;
  initialSecondFactor: SecondFactor;
  username: string;
  isStandalone?: boolean;
  className?: string;
  verificationVariant?: VerificationVariant;
  allowedSecondFactors?: SecondFactor[];
  maxWidth?: string;
  customLabels?: Custom2faLabels;
}

const lengthMap: Record<SecondFactor, number> = {
  totp: 6,
  otp_by_email: 6,
  recovery_codes: 10,
};
const verificationWidthClass: Record<SecondFactor, string> = {
  totp: 'max-w-[300px]',
  otp_by_email: 'max-w-[300px]',
  recovery_codes: 'max-w-[450px]',
};

const LoginSecondFactor = ({
  isOauth,
  initialSecondFactor,
  username,
  isStandalone = false,
  className = '',
  verificationVariant = 'small',
  allowedSecondFactors,
  customLabels,
  maxWidth,
}: Params) => {
  const intl = useIntl();
  const {
    values,
    updateValues,
    updateType,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
    reset,
  } = useLoginSecondFactorForm({ isOauth, initialSecondFactor, username });
  const updateTypeHandle = (type: SecondFactor) => {
    updateType(type);
    setEmailResent(type === 'otp_by_email');
    if (type === 'otp_by_email') {
      setEmailResentSaved(false);
      reset();
      setTimeout(() => {
        setEmailResentSaved(true);
      }, 500);
    }
  };
  const getError = getElementError(errors, hasSubmitted);
  const [emailResent, setEmailResent] = useState(false);
  const [emailResentSaved, setEmailResentSaved] = useState(false);
  const session = useSession();
  const canUseTotp =
    session.data?.allowed_second_factors?.includes('totp') &&
    (allowedSecondFactors || ['totp']).includes('totp');
  const canUseEmail =
    session.data?.allowed_second_factors?.includes('otp_by_email') &&
    (allowedSecondFactors || ['otp_by_email']).includes('otp_by_email');
  const canUseRecovery =
    session.data?.allowed_second_factors?.includes('recovery_codes') &&
    (allowedSecondFactors || ['recovery_codes']).includes('recovery_codes');
  return (
    <form
      method="post"
      onSubmit={handleSubmit}
      data-testid="login-form-second-factor"
      className={`${className} space-y-4`}
      noValidate
    >
      <Text size="S" color="nad-alps-night">
        {values.second_factor === 'totp' &&
          (customLabels?.totp?.description || (
            <FormattedMessage
              id="c8faoP"
              defaultMessage="Please enter the code from your authenticator app."
              description="Label for TOTP second factor."
            />
          ))}
        {values.second_factor === 'otp_by_email' &&
          (customLabels?.otp_by_email?.description || (
            <FormattedMessage
              id="bDlPEv"
              defaultMessage="Please enter the code sent to your email below."
              description="Label for otp by email second factor."
            />
          ))}
        {values.second_factor === 'recovery_codes' &&
          (customLabels?.recovery_codes?.description || (
            <FormattedMessage
              id="8A5V+d"
              defaultMessage="Please enter a recovery code to sign in."
              description="Label for recovery codes second factor."
            />
          ))}
      </Text>
      <div
        className={`${
          maxWidth || verificationWidthClass[values.second_factor]
        }`}
      >
        <InputVerification
          name="otp"
          variant={verificationVariant}
          value={values.otp}
          onChange={(value) => {
            updateValues({ ...values, otp: value });
          }}
          label={
            customLabels?.[values.second_factor]?.label ||
            intl.formatMessage({
              id: 'pGY1q0',
              defaultMessage: 'Authentication Code',
              description: 'Second factor label for login form.',
            })
          }
          length={lengthMap[values.second_factor]}
          error={getError('otp')}
        />
      </div>
      <Text size="S" color="nad-alps-night">
        {/* TOTP */}
        {values.second_factor === 'totp' && canUseEmail && (
          <FormattedMessage
            id="l/ncXk"
            defaultMessage="Having problems? <button1>Send code to email</button1> or use a <button2>recovery code</button2>"
            description="Help text for entering a TOTP sencond factor code."
            values={{
              button1: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('otp_by_email');
                  }}
                >
                  {text}
                </button>
              ),
              button2: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('recovery_codes');
                  }}
                >
                  {text}
                </button>
              ),
            }}
          />
        )}
        {values.second_factor === 'totp' && !canUseEmail && (
          <FormattedMessage
            id="eX1zNN"
            defaultMessage="Having problems? Use a <button1>recovery code</button1>"
            description="Help text for entering a TOTP sencond factor code (only recovery codes)."
            values={{
              button1: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('recovery_codes');
                  }}
                >
                  {text}
                </button>
              ),
            }}
          />
        )}

        {/* OTP By Email */}
        {values.second_factor === 'otp_by_email' && canUseTotp && (
          <FormattedMessage
            id="FyM5QS"
            defaultMessage="Having problems? <button1>Resend email</button1>, use <button2>authenticator app</button2>, or use a <button3>recovery code</button3>"
            description="Help text for entering a TOTP sencond factor code."
            values={{
              button1: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('otp_by_email');
                  }}
                >
                  {text}
                </button>
              ),
              button2: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('totp');
                  }}
                >
                  {text}
                </button>
              ),
              button3: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('recovery_codes');
                  }}
                >
                  {text}
                </button>
              ),
            }}
          />
        )}

        {values.second_factor === 'otp_by_email' && !canUseTotp && (
          <FormattedMessage
            id="z9wS/G"
            defaultMessage="Having problems? <button1>Resend email</button1> or use a <button2>recovery code</button2>"
            description="Help text for entering a TOTP sencond factor code."
            values={{
              button1: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('otp_by_email');
                  }}
                >
                  {text}
                </button>
              ),
              button2: (text) => (
                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    updateTypeHandle('recovery_codes');
                  }}
                >
                  {text}
                </button>
              ),
            }}
          />
        )}

        {/* Recovery Codes */}
        {values.second_factor === 'recovery_codes' &&
          canUseTotp &&
          !canUseEmail && (
            <FormattedMessage
              id="YKPDQT"
              defaultMessage="Having problems? Use <button1>authenticator app</button1>"
              description="Help text for entering a TOTP sencond factor code (only recovery codes)."
              values={{
                button1: (text) => (
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      updateTypeHandle('totp');
                    }}
                  >
                    {text}
                  </button>
                ),
              }}
            />
          )}
        {values.second_factor === 'recovery_codes' &&
          !canUseTotp &&
          canUseEmail && (
            <FormattedMessage
              id="T39uZb"
              defaultMessage="Having problems? <button1>Send to email</button1>"
              description="Help text for entering a TOTP sencond factor code (only recovery codes)."
              values={{
                button1: (text) => (
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      updateTypeHandle('otp_by_email');
                    }}
                  >
                    {text}
                  </button>
                ),
              }}
            />
          )}
        {values.second_factor === 'recovery_codes' &&
          canUseTotp &&
          canUseEmail && (
            <FormattedMessage
              id="eTShcr"
              defaultMessage="Having problems? <button1>Send code to email</button1> or <button2>authenticator app</button2>"
              description="Help text for entering a TOTP sencond factor code."
              values={{
                button1: (text) => (
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      updateTypeHandle('otp_by_email');
                    }}
                  >
                    {text}
                  </button>
                ),
                button2: (text) => (
                  <button
                    type="button"
                    className="underline"
                    onClick={() => {
                      updateTypeHandle('totp');
                    }}
                  >
                    {text}
                  </button>
                ),
              }}
            />
          )}
      </Text>
      <FormErrorMessage className="mt-4" errors={submitError} />
      <div className="flex gap-2 sm:gap-0 flex-col sm:flex-row items-center mt-5">
        <Button
          isLoading={isSubmitting}
          size={isStandalone ? 'normal' : 'small'}
          width={isStandalone ? 'large' : 'normal'}
          className={isStandalone ? 'mx-auto block' : ''}
          data-testid="submit-second-factor"
          disabled={isSubmitting || (hasSubmitted && hasErrors)}
        >
          <FormattedMessage
            id="HC1HLX"
            defaultMessage="Sign In"
            description="Sign in button"
          />
        </Button>
      </div>
      {emailResent && (
        <TextUppercase
          size="S"
          color="nad-ming"
          className={`text-center sm:text-left transition-opacity duration-200 ease-in ${
            emailResentSaved ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <FormattedMessage
            id="UyCqXB"
            defaultMessage="Email Sent"
            description="Confirmation that the email code was sent to login."
          />
        </TextUppercase>
      )}
    </form>
  );
};

export default LoginSecondFactor;
