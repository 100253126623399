import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getCsrfToken, getSession, signIn } from 'next-auth/react';
import { NextRouter, useRouter } from 'next/router';

import { IntlShape, useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  validateRequiredFields,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import { clearUserSpecificSessionStorage } from './use-tithe-form';
import { clearCache } from '@hooks/use-user';
import { fetchPost } from '@fetch/helpers';
import { OauthAuthorizeResponse } from '@models/http/oauth-authorize-response';
import { SecondFactor } from '@models/user';
import { sleep } from 'helpers/sleep';

interface LoginFormData {
  username: string;
  password: string;
  otp?: string;
  stay_signed_in: boolean;
  showPassword: boolean;
}

interface ValidationParams {
  requiredFields: (keyof LoginFormData)[];
  values: LoginFormData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

interface Params {
  isOauth: boolean;
  grantType?: 'password' | 'first_factor_otp_by_email';
  username?: string;
  onSuccess?: (requiresSecondFactor?: boolean) => void;
}

interface CallbackUrlParams {
  router: NextRouter;
}

export const getLoginCallbackUrl = ({ router }: CallbackUrlParams) => {
  let { callbackUrl, orgId } = router.query;
  callbackUrl = callbackUrl
    ? (callbackUrl as string)
    : orgId
      ? `/donate/${router.query.orgId}/payment`
      : undefined;
  return callbackUrl;
};

interface OauthParams {
  router: NextRouter;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setSubmitError: (data: string[]) => void;
  intl: IntlShape;
}

export const authorizeOauth = async ({
  router,
  setIsSubmitting,
  setSubmitError,
  intl,
}: OauthParams) => {
  const csrfToken = await getCsrfToken();
  const {
    client_id,
    redirect_uri,
    response_type,
    scope,
    code_challenge,
    code_challenge_method,
    state,
  } = router.query;
  const scopeParsed =
    router.query['scope[]'] && Array.isArray(router.query['scope[]'])
      ? router.query['scope[]'].join(' ')
      : router.query['scope[]'] || scope;
  const authorizeResponse = await fetchPost({
    url: '/api/v3/oauth/authorize',
    data: {
      client_id,
      redirect_uri,
      response_type,
      scope: scopeParsed,
      code_challenge,
      code_challenge_method,
      state: state || '',
      csrfToken,
    },
  });
  if (authorizeResponse.ok) {
    const data: OauthAuthorizeResponse = await authorizeResponse.json();
    router.replace(data.redirect_uri);
    return true;
  } else {
    setIsSubmitting(false);
    setSubmitError([getIntlErrorMessage('loginFailed', intl)]);
    return false;
  }
};

/**
 * mutate forces existing SWR methods to revalidate.
 * In this case it clears out old login information if present.
 * @see https://swr.vercel.app/docs/mutation
 */
export const clearExistingProfileInformation = () => {
  // Clearing all cached data to erase all potential user specific info
  // such as donations, saved payment methods, etc.
  clearCache();
  clearUserSpecificSessionStorage();
};

const useLoginForm = ({
  isOauth,
  grantType = 'password',
  username = '',
  onSuccess,
}: Params) => {
  const isOnline = useOnline();
  const requiredFieldsPassword: (keyof LoginFormData)[] = useMemo(() => {
    return ['username', 'password'];
  }, []);
  const requiredFieldsOtp: (keyof LoginFormData)[] = useMemo(() => {
    return ['username', 'otp'];
  }, []);
  const requiredFields =
    grantType === 'password' ? requiredFieldsPassword : requiredFieldsOtp;
  const formData: LoginFormData = {
    username: username,
    password: '',
    otp: '',
    stay_signed_in: false,
    showPassword: false,
  };
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<LoginFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requiresSecondFactor, setRequiresSecondFactor] = useState(false);
  const [allowedSecondFactors, setAllowedSecondFactors] =
    useState<SecondFactor[]>();
  const [preferredSecondFactor, setPreferredSecondFactor] =
    useState<SecondFactor>();
  const router = useRouter();
  const intl = useIntl();

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, values]);

  const handleChange = handleFormChange({ values, setValues });

  const updateValues = (newValues: Partial<LoginFormData>) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    try {
      const callbackUrl = getLoginCallbackUrl({ router });
      const credentials =
        grantType === 'first_factor_otp_by_email'
          ? {
              username: values.username,
              otp: values.otp,
            }
          : {
              username: values.username,
              password: values.password,
            };
      const response = await signIn('credentials', {
        ...credentials,
        grant_type: grantType,
        redirect: false,
        callbackUrl,
      });
      if (response?.ok) {
        clearExistingProfileInformation();
        let session = await getSession();
        if (!session) {
          // This is completely unexpected and should NOT happen.
          // We are logging this to see if this ever happens.
          // Might be related to https://app.asana.com/0/1205435369594172/1207249246959022
          fetchPost({
            url: `/api/log-error`,
            data: {
              title:
                'Session missing after successful login (will try again in 2s)',
              username: `${values.username}`,
            },
          });
          await sleep(2_000);
          session = await getSession();
          if (session) {
            fetchPost({
              url: `/api/log-error`,
              data: {
                title: 'Session found after 2s delay',
                username: `${values.username}`,
              },
            });
          } else {
            fetchPost({
              url: `/api/log-error`,
              data: {
                title: 'Session missing after 2s delay',
                username: `${values.username}`,
              },
            });
          }
        }
        if (!session) {
          setIsSubmitting(false);
          setSubmitError([getIntlErrorMessage('loginSessionFailed', intl)]);
          return;
        }
        const needsSecondFactor = !session?.scope?.includes('authenticated');
        if (needsSecondFactor) {
          // Remove this if it is causing logs to grow and/or becomes unhelpful
          // This shouldn't happen much in production as of August 26, 2024 becasue
          // 2FA is not readily available. You have to manually know the url to turn this setting on.
          fetchPost({
            url: `/api/log-error`,
            data: {
              title: 'Session requires 2FA',
              session,
            },
          });
          setRequiresSecondFactor(true);
          setAllowedSecondFactors(session?.allowed_second_factors);
          setPreferredSecondFactor(session?.preferred_second_factor);
          setIsSubmitting(false);
          onSuccess?.(needsSecondFactor);
          return;
        }
        if (isOauth) {
          authorizeOauth({ router, setIsSubmitting, setSubmitError, intl });
        } else {
          if (response?.url && callbackUrl) {
            router.replace(response.url);
          } else {
            router.push('/account');
          }
        }
        onSuccess?.(false);
      } else {
        setIsSubmitting(false);
        setSubmitError([getIntlErrorMessage('loginFailed', intl)]);
      }
    } catch (err) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('loginFailed', intl)]);
    }
  };

  return {
    handleChange,
    updateValues,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    requiresSecondFactor,
    allowedSecondFactors,
    preferredSecondFactor,
  };
};

export default useLoginForm;
