import React, { useEffect, useMemo, useState } from 'react';
import { getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

import { useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  validateRequiredFields,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import {
  authorizeOauth,
  clearExistingProfileInformation,
  getLoginCallbackUrl,
} from './use-login-form';
import { fetchPost } from '@fetch/helpers';
import { SecondFactor } from '@models/user';

interface LoginFormData {
  username: string;
  otp: string;
  second_factor: SecondFactor;
}

interface ValidationParams {
  requiredFields: (keyof LoginFormData)[];
  values: LoginFormData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

interface Params {
  isOauth: boolean;
  initialSecondFactor: SecondFactor;
  username: string;
}

const sendOtpByEmail = async (email: string) => {
  await fetchPost({
    url: `/api/v3/authentication_factors/otp_by_email/send`,
    data: {
      email,
    },
  });
};

const useLoginSecondFactorForm = ({
  isOauth,
  initialSecondFactor,
  username,
}: Params) => {
  const isOnline = useOnline();
  const requiredFields: (keyof LoginFormData)[] = useMemo(() => {
    return ['username', 'otp', 'second_factor'];
  }, []);
  const formData: LoginFormData = {
    username,
    otp: '',
    second_factor: initialSecondFactor,
  };
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<LoginFormData>>();
  const [sentOtpEmail, setSentOtpEmail] = useState(false);
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const intl = useIntl();

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, values]);

  useEffect(() => {
    if (!sentOtpEmail && initialSecondFactor === 'otp_by_email') {
      sendOtpByEmail(values.username);
      setSentOtpEmail(true);
    }
  }, [initialSecondFactor, values, sentOtpEmail]);

  const handleChange = handleFormChange({ values, setValues });

  const updateValues = (newValues: Partial<LoginFormData>) => {
    setValues((v) => ({
      ...v,
      ...newValues,
    }));
  };

  const updateType = async (type: SecondFactor) => {
    const otp = type === values.second_factor ? values.otp : '';
    updateValues({ second_factor: type, otp });

    if (type === 'otp_by_email') {
      await sendOtpByEmail(values.username);
    }
  };

  const reset = () => {
    setValues((v) => ({
      ...v,
      otp: '',
    }));
    setErrors(undefined);
    setFormHasErrors(false);
    setSubmitError(undefined);
    setHasSubmitted(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    try {
      const callbackUrl = getLoginCallbackUrl({ router });
      const response = await signIn('credentials', {
        username: values.username,
        otp: values.otp,
        type: values.second_factor,
        grant_type: 'second_factor',
        redirect: false,
        callbackUrl,
      });
      if (response?.ok) {
        clearExistingProfileInformation();
        const session = await getSession();
        const needsSecondFactor = !session?.scope?.includes('authenticated');
        if (needsSecondFactor) {
          setIsSubmitting(false);
          setSubmitError([getIntlErrorMessage('processingError', intl)]);
          return;
        }
        if (isOauth) {
          authorizeOauth({ router, setIsSubmitting, setSubmitError, intl });
        } else {
          if (response?.url && callbackUrl) {
            router.replace(response.url);
          } else {
            router.push('/account');
          }
        }
      } else {
        setIsSubmitting(false);
        setSubmitError([getIntlErrorMessage('processingError', intl)]);
      }
    } catch (err) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
    }
  };

  return {
    handleChange,
    updateValues,
    updateType,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    reset,
  };
};

export default useLoginSecondFactorForm;
